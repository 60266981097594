const courseEnrollment = [
    { text: "COURSES", value: "title", width: "40%"},
    { text: "INSTRUCTOR", value: "uploader.name", align: 'center'},
    { text: "PENDING", value: "pending_invoices", align: 'center'},
    { text: "ACCEPTED", value: "success_invoices", align: 'center'},
    { text: "DISQUALIFIED", value: "fail_invoices", align: 'center'},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const pendingCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "NOMINATION FORM", value: "nomination_form", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const approvedCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "DATE APPROVED", value: "date_approved", align: 'center'},
    { text: "NOMINATION FORM", value: "nomination_form", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
]

const disqualifiedCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "NOMINATION FORM", value: "nomination_form", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
]

export { courseEnrollment, pendingCourseEnrollment, approvedCourseEnrollment, disqualifiedCourseEnrollment }